import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './index.css'

// Importa e registra o FontAwesomeIcon
import { FontAwesomeIcon } from './fontawesome'

const app = createApp(App)

// Registra o componente globalmente
app.component('font-awesome-icon', FontAwesomeIcon)

app.use(router).mount('#app')

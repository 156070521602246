<template>
  <div>
    <div v-if="isMobile">
      <div
        class="fixed flex pr-4 justify-between top-0 z-50 bg-[#393b3c] w-full h-16 md:hidden"
      >
        <div class="flex items-center ml-4">
          <img src="../assets/LOGO_WHITE.png" alt="Logo" class="h-8" />
        </div>
        <button @click="isMenuOpen = !isMenuOpen">
          <span class="text-3xl text-white">☰</span>
        </button>
      </div>
      <div
        v-if="isMobile && isMenuOpen"
        class="fixed inset-0 bg-black bg-opacity-75 z-40 flex flex-col items-center justify-center"
      >
        <ul class="space-y-6">
          <li v-for="link in navLinks" :key="link.name">
            <router-link
              :to="link.path"
              class="text-white text-xl tracking-wider"
              @click="isMenuOpen = false"
            >
              {{ link.name }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>

    <header
      v-else
      class="flex flex-col items-center justify-center bg-[#393b3c] text-white pt-4"
      :class="isNavbarFixed ? 'mb-12' : 'pt-2'"
    >
      <div class="mb-4">
        <img src="../assets/LOGO_WHITE.svg" alt="Logo" class="logo h-28" />
      </div>
      <nav
        class="w-full transition-all duration-300 ease-in-out bg-[#393b3c]"
        :class="{
          'fixed top-0 z-50 shadow-md': isNavbarFixed,
          relative: !isNavbarFixed,
        }"
      >
        <ul
          class="flex flex-col md:flex-row items-center justify-center md:space-x-10 lg:space-x-56 py-4"
        >
          <li v-for="link in navLinks" :key="link.name">
            <router-link
              :to="link.path"
              class="text-white hover:text-opacity-75 transition font-coolvetica-light-light text-xl tracking-wider"
            >
              {{ link.name }}
            </router-link>
          </li>
        </ul>
      </nav>
    </header>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue'
import { useRoute } from 'vue-router'

const navLinks = [
  { name: 'HOME', path: '/' },
  // { name: 'SOBRE NÓS', path: '/sobre' },
  // { name: 'SERVIÇOS', path: '/servicos' },
  // { name: 'PROJETOS', path: '/projetos' },
]

const route = useRoute()

const isMobile = ref(window.innerWidth < 768)

const isNavbarFixed = ref(false)
let topOfNavbar = 0
const isMenuOpen = ref(false)

function handleResize() {
  isMobile.value = window.innerWidth < 768
}

watch(
  () => route.path,
  () => {
    isMenuOpen.value = false
  }
)
watch(isMenuOpen, (newValue) => {
  document.body.style.overflow = newValue ? 'hidden' : ''
})

onMounted(() => {
  const header = document.querySelector('header')
  topOfNavbar = header.offsetTop + 150
  window.addEventListener('scroll', handleScroll)
  window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
  window.removeEventListener('resize', handleResize)
})

function handleScroll() {
  if (window.scrollY > topOfNavbar) {
    isNavbarFixed.value = true
  } else {
    isNavbarFixed.value = false
  }
}
</script>
<style>
.logo {
  image-rendering: optimizeQuality;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>

import { createRouter, createWebHistory } from 'vue-router'

// Importe seus componentes de página
import Home from '../pages/index.vue'
import Sobre from '../pages/sobre.vue'

// Mapeamento de rotas
const routes = [
  { path: '/', component: Home },
  { path: '/sobre', component: Sobre },
  // adicione mais rotas conforme necessário
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
